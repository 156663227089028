const mapElement = document.querySelector(".imperial-footer-map");
const latitude = "49.43392";
const longitude = "-2.66001";

mapboxgl.accessToken =
  "pk.eyJ1IjoicXBpZGRpZ2l0YWwiLCJhIjoiY20zaHJicjdjMGprZjJtcXo4cW5mejVhMSJ9.gqRUtAdLgI-1Z0IYWG4b-A";
const map = new mapboxgl.Map({
  container: mapElement,
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 14,
});

const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#005a9a"/><path d="M35.67,27.67h-4.92v-4.03l4.92.09v3.94ZM34.23,22.02l-2.61-.07v-3.25l2.61.09v3.22ZM34.23,17.72l-2.61-.08v-3.01l2.61.19v2.9ZM28.83,18.46v3.32s-1.47-.01-1.47-.01v-3.32h1.47ZM28.83,17.29l-1.47-.05v-2.99l1.47.06v2.98ZM24.3,21.66l-2.86-.09v-3.43l2.86.15v3.37ZM24.3,17.12l-2.86-.14v-3.18l2.86.28v3.04ZM20.2,23.47l7.61.09v4.07h-7.61v-4.16ZM16.72,21.57h-.88v-1.6h.88v1.6ZM14.52,22.75h-1.38v-2.78h1.38v2.78ZM9.01,22.48h-.48v-2.15l.48-.04v2.19ZM7.64,22.66h-.48v-2.15l.48-.04v2.19ZM6.4,22.93h-.48v-2.13l.48-.03v2.15ZM37,28.32l-.2-13.97h-.07s-6.29-3.79-6.29-3.79l-10.66-1.11-7.08,5.21-.58.2v2.78s-2.67,0-2.67,0c0,0-.17,1.08-.96,1.47-.78.39-3.49.66-3.49.66v.51s0,0,0,0l.18,7.39,4.79.65h0s8,0,8,0v-.02h19.02Z" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
